import React from "react";
import ReactPaginate from "react-paginate";

interface ICustomPaginationProps {
  numPages: number | string;
  itemsPerPage?: number | string;
  currentPage: number | string;
  handlePageClick: (_selectedItem: { selected: number }) => void;
}

const CustomPagination = ({
  numPages,
  currentPage,
  handlePageClick,
}: ICustomPaginationProps) => (
  <ReactPaginate
    forcePage={Number(currentPage) - 1}
    nextLabel=" >"
    onPageChange={handlePageClick}
    pageRangeDisplayed={3}
    marginPagesDisplayed={2}
    pageCount={Number(numPages)}
    previousLabel="< "
    pageClassName="page-item"
    pageLinkClassName="page-link"
    previousClassName="page-item"
    previousLinkClassName="page-link"
    nextClassName="page-item"
    nextLinkClassName="page-link"
    breakLabel="..."
    breakClassName="page-item"
    breakLinkClassName="page-link"
    containerClassName="pagination"
    activeClassName="active"
  />
);

export default CustomPagination;
